<template>
  <section class="content-height" id="home">
    <div>
      <swiper class="swiper home-slider" :options="swiperOption">

        <!--        <swiper-slide>-->
        <!--          <div class="items banner-bg1 banner-bg h-100">-->
        <!--            <div class="container h-100">-->
        <!--              <div class="row position-relative text-center h-100">-->
        <!--                <div class="col-10 hero-text-wrapper">-->
        <!--                  <p class="hero-text-1">Let the world know you as a </p>-->
        <!--                  <p class="hero-text-2">CELEBRITY.</p>-->
        <!--                  <p class="sub-hero-text">We offer customized 'Personal Branding' solutions to help every professional-->
        <!--                    stand out and promote their best version to the world. Stay ahead. Go famous</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->

        <swiper-slide>
          <div class="items banner-bg h-100">
            <div>
              <!--              <div class="video-container">-->
              <!--                <video autoplay loop muted class="wrapper__video" style="width: 100%;">-->
              <!--                  <source src="https://surestory.in/video.mp4">-->
              <!--                </video>-->
              <!--              </div>-->
              <div class="video-container">
                <video autoplay loop muted playsinline class="wrapper__video" style="width: 100%;">
                  <!--                  <source src="https://surestory.in/video.mp4" type="video/mp4">-->
                  <source src="../assets/video/pexels.mp4" type="video/mp4">
                </video>
              </div>
              <div class="video-overlay"></div>
            </div>
            <div class="container h-100">
              <div class="row position-relative text-center h-100">
                <div class="col-12 hero-text-wrapper h-100">
                  <div class="fl-x fl-a-c h-100">
                    <div class="w-100">
                      <p class="hero-text-1">WE LOVE TO MIND YOUR BUSINESS AND REPUTATION</p>
                      <p class="hero-text-2">AND WE DO IT LIKE NO ONE ELSE.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!--        <swiper-slide>-->
        <!--          <div class="items banner-bg2 banner-bg h-100">-->
        <!--            <div class="container h-100">-->
        <!--              <div class="row position-relative text-center h-100">-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->

        <!--        <swiper-slide>-->
        <!--          <div class="items banner-bg3 banner-bg h-100">-->
        <!--            <div class="container h-100">-->
        <!--              <div class="row position-relative text-center h-100">-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->

        <!--        <div class="swiper-pagination" slot="pagination"></div>-->
      </swiper>
    </div>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide
  }, data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        }
      }
    }
  }
}
</script>

<style scoped>

.video-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.video-container .wrapper__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


</style>
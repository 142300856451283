<template>
  <section class="content-height" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row inner-content-height">
            <div class="align-items-center col-lg-4 d-flex ">
              <div class="contact-container d-flex align-items-center justify-content-center justify-content-lg-start">
                <transition name="fade" mode="out-in">

                  <div class="text-center text-lg-left" v-if="state === 'S'" key="'S">
                    <p class="text-success bg-white">The mail has been successfully submitted.</p>
                    <router-link to="/" class="btn btn-primary xeo-button-2 btn-sm">Back to Home</router-link>
                  </div>

                  <div class="text-center text-lg-left" v-else-if="state === 'F'" key="'F">
                    <p class="text-danger bg-white">The mail delivery has been failed.</p>
                    <router-link to="/" class="btn btn-primary xeo-button-2 btn-sm">Back to Home</router-link>
                  </div>

                  <div class="alert alert-info mb-0 w-100 text-center" v-else-if="state === 'L'" key="'L">
                    <div class="">
                      <loading-animation/>
                    </div>
                    <p class="mb-0 mt-4">Please wait while communicate with the server</p>
                  </div>

                  <form v-else @submit.prevent="onSubmit">
                    <div class="row ">
                      <div class="form-item col-12 mb-3">
                        <h1><b>LEAVE US A MESSAGE</b></h1>
                      </div>
                      <div class="form-group col-lg-12 mb-4">
                        <!--                <label>Full Name</label>-->
                        <input type="text" class="form-control xeo-input-1" placeholder="Name *" v-model="name" required>
                      </div>
                      <div class="form-group col-lg-12 mb-4">
                        <!--                <label>Telephone</label>-->
                        <input class="form-control xeo-input-1" placeholder="Telephone *" v-model="phone" required
                        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                       type="number" maxlength="10">

                      </div>
                      <div class="form-group col-lg-12 mb-4">
                        <!--                <label>Email</label>-->
                        <input type="email" class="form-control xeo-input-1" placeholder="Email *" v-model="email" required>
                      </div>
                      <div class="form-group col-lg-12 mb-4">
                        <!--                <label>Your Message</label>-->
                        <textarea class="form-control xeo-input-1" placeholder="Your Message" v-model="message"
                                  rows="3" required></textarea>
                      </div>
                    </div>
                    <div class="row justify-content-center justify-content-lg-start">
                      <div class="col-5 col-lg-4">
                        <button type="submit" class="btn btn-primary xeo-button-2 btn-block">Submit</button>
                      </div>
                    </div>


                  </form>

                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


import axios from 'axios'
import LoadingAnimation from "@/components/LoadingAnimation";


export default {
  name: "contact",
  components: {LoadingAnimation},
  data() {
    return {
      state: 'D',
      name: '',
      phone: '',
      email: '',
      message: ''
    }

  },

  methods: {
    onSubmit() {
      console.log('Here....')
      const bodyFormData = new FormData();
      bodyFormData.append('name', this.name);
      bodyFormData.append('phone', this.phone);
      bodyFormData.append('email', this.email);
      bodyFormData.append('message', this.message)

      const that = this;

      this.state = 'L';

      axios({
        method: "post",
        url: "https://surestory.in/mail.php",
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(function (response) {
        that.onSubmitSuccess(response);
      }).catch(function (response) {
        that.onSubmitFailed(response);
      });
    },
    onSubmitSuccess() {
      this.state = 'S';
    },

    onSubmitFailed() {
      this.state = 'F';
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0
}
</style>
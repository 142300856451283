<template>
  <section class="content-height" id="services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="service-card">
            <div class="row">
              <div class="col-lg-6 my-3">
                <h2><b>Image Management</b></h2>

                <p>It's important how you project yourself in the world of the internet. Your image should correlate
                  with your visions, goals, and capabilities. Our objective is to deliver a positive and impressive
                  impact on your image in the minds of every person who finds you on the internet. Helping you achieve
                  an influential status and iconic personal brand image, digitally.</p>
              </div>
              <div class="col-lg-6 my-3">
                <h2><b>Social Media Management</b></h2>

                <p>Being there on popular social media channels is easy. What becomes difficult is to initiate
                  conversations that are engaging, relevant, and credible with your fans and followers. Regular
                  conversations that are crafted, verified, scheduled, and posted for maximum visibility and engagement.
                  Because even a single post can defame a well-endearing brand's image overnight. It's good to
                  be social. But it's better to be socially intelligent.</p>
              </div>
              <div class="col-lg-6 my-3">
                <h2><b>Online Reputation Management</b></h2>

                <p>ORM is like eavesdropping. Keeping track of who is talking about you in the world of the internet. It
                  could be an unhappy customer tweeting bad about you. Or a delighted journalist complementing you in
                  his article. An image posted or a video uploaded. It could be anything. The point is to be aware of
                  every single news update about your brand across the globe. And ensure it is taken good care of. Quite
                  a task isn't it?. Well with our dedicated Online Reputation Management system you will be armed
                  with real-time reports and pro-active solutions keeping your reputation well-guarded round the
                  clock.</p>
              </div>
              <div class="col-lg-6 my-3">
                <h2><b>Digital PR</b></h2>

                <p>This is when you have terrific news to share, but wonder will it be taken by any media. Leave the
                  worry to us as we help your news evolve as conversations through strategic content marketing and
                  search optimization. And that's not over; you will also be trained on handling media interactions
                  and responses to help your personal brand evolve as compelling newsworthy stories. </p>
              </div>
              <div class="col-lg-6 my-3">
                <h2><b>Viral Video Production</b></h2>

                <p>A ten-second advertisement time in any popular television channel will cost you anything between
                  30,000-500,000 (INR). And the worst part is it never guarantees you results or reveals the number of
                  people who have watched your ad. But in the world of digital things are different. You can now
                  convey your expertise, talent, and messages through engaging digital videos and can decide to show
                  this to customers whom you think are potential across the world. All this with a budget that you
                  decide upon. We will be there for you with our decade-long experience in video production,
                  awesome creativity, and digital media planning till you become a digital rock star. </p>
              </div>
              <div class="col-lg-6 my-3">
                <h2><b>Visual Identity Creation</b></h2>

                <p class="ex-list">We believe in love at first sight and for us every customer touchpoint means
                  integral. Because you are likely to be judged by the visiting card you have just shared. Or the
                  brochure you gave a little ago. Your company logo, letterhead, marketing documents, website,
                  signboards etc play a crucial role in defining your stature in front of your customers. Our expertise
                  in working with leading Indian brands for over a decade will help you achieve a credible brand
                  identity among your potential customers.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "services"
}
</script>

<style scoped>

</style>
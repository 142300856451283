import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views'
import About from '../views/about'
import Service from '../views/services'
import Clients from '../views/client'
import Contact from '../views/contact'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('../base'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/about/',
                name: 'About',
                component: About
            },
            {
                path: '/service/',
                name: 'Service',
                component: Service
            },
            {
                path: '/clients/',
                name: 'Clients',
                component: Clients
            },
            {
                path: '/contact/',
                name: 'Contact',
                component: Contact
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router

<template>


  <section class="content-height" id="client">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row logo-images">
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/01.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/02.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/03.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/04.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/05.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/06.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/07.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/08.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/09.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/10.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/11.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/12.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/13.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/14.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/15.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/16.jpg" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/17.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/18.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/19.jpg" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/20.png" alt="">
            </div>
            <div class="col-6 col-md-4 col-lg-2 mb-4 items">
              <img class="img-fluid" src="../assets/img/brands/21.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "client"
}
</script>

<style scoped lang="scss">
.logo-images {
  img {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
<template>
  <section class="content-height" id="about">
    <div class="container">
      <div class="d-flex align-items-center inner-content-height col-xl-8">
        <div class="row">
          <div class="col-xl-6">
            <h2>Who Are You?</h2>
            <p> We don't intend to question your identity. But have you ever thought about what is so common
              among movie stars, politicians, sportsmen, artists, and such socially known individuals? Everybody
              knows them and they don't need any introduction. You just need to search for them on the internet
              and that&#39;s it. You could be a professional (doctor, chartered accountant, lawyer, fashion designer,
              photographer, etc), writer, performing artist, or even a student. You may have exceptional skill sets,
              unmatched expertise, and capabilities. But does the world know about it? If the answer is <b class="red">NO</b>,
              then it's time you say <b class="red">YES</b> to the beginning of shaping up the power brand in you.
            </p>
          </div>
          <div class="col-xl-6">
            <h2 class="red">Who are We?</h2>

            <p>We are SureStory, a reputation management company that focuses on delivering customized
              communication solutions that effectively cement unique value proposition for individuals and
              companies, especially on digital mediums. Unlike traditional media, digital platforms today play a
              vital role in defining your identity. While the news that appears in a traditional newspaper is seen
              and forgotten the next day, the same news on digital media stays on the internet forever. A funny
              photograph on Facebook, an embarrassing YouTube video, a customer complaint on the website, a
              shabby profile on Linkedin can define your future. With the power of search engines like GOOGLE
              anyone can search your name on the internet and what comes out as the results will be your
              internet reputation. </p>

          </div>
          <br>
          <div class="col-12">
            <p>At <span class="red"> SureStory</span> we believe in giving your image and reputation a whole new
              positive makeover and help you project yourself convincingly and confidently to the world that matters to
              you.</p>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "about"
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./client.vue?vue&type=template&id=323d0722&scoped=true&"
import script from "./client.vue?vue&type=script&lang=js&"
export * from "./client.vue?vue&type=script&lang=js&"
import style0 from "./client.vue?vue&type=style&index=0&id=323d0722&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323d0722",
  null
  
)

export default component.exports